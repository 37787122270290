<template>
  <BaseFrontdoor>
    <HAWHome />
  </BaseFrontdoor>
</template>

<script>
import HAWHome from "../../haw-components-hac/Shared/HAWHome";

export default {
  name: "hac",

  components: {
    HAWHome,
  },
};
</script>
